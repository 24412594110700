import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import PortfolioBanner from "./PortfolioBanner";
import PortfolioItems from "./PortfolioItems";

const Portfolio = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <PortfolioBanner />
      <PortfolioItems />
      <FooterThree />
      <a href="https://api.whatsapp.com/send?phone=919845997879"><img src="../../assets/img/logo/contact_icon.png" alt="" style={{position: "fixed", zIndex: "9999", bottom: "5%", right: "7%", height: "70px", width: "70px"}} className="img-fluid zoom"/></a>
    </>
  );
};

export default Portfolio;
