import React from "react";
import Brands from "../../common/Brands/Brands";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import Services from "../../HomeTwo/Services";
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany";
import AboutFeature from "./AboutFeature";

const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />
      <Services />
      {/* <AboutFeature /> */}
      <Testimonial />
      <Brands />
      <FooterThree />
      <a href="https://api.whatsapp.com/send?phone=919845997879"><img src="../../assets/img/logo/contact_icon.png" alt="" style={{position: "fixed", zIndex: "9999", bottom: "5%", right: "7%", height: "70px", width: "70px",}} className="img-fluid zoom"/></a>
    </>
  );
};

export default About;
