import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import ImagePopup from "../../common/Modals/ImagePopup";

// portfolio items
const portfolio_data = [
  {
    id:0,
    img:'/assets/img/boards/sLcIZy0cWSDIbSfQ1ADD4YiqRfIEp7mC_uNs08LtsMI=_plaintext_638248507697180791.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:1,
    img:'/assets/img/boards/IMG-20230713-WA0039.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:2,
    img:'/assets/img/boards/IMG-20230713-WA0033.jpg',
    tag:"Business",
    title:"LED Board"
  },
  {
    id:3,
    img:'/assets/img/boards/IMG-20230713-WA0036.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:4,
    img:'/assets/img/boards/IMG-20230713-WA0037.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:5,
    img:'/assets/img/boards/IMG-20230713-WA0041.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:6,
    img:'/assets/img/boards/IMG-20230713-WA0046.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:7,
    img:'/assets/img/boards/IMG-20230713-WA0055.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:8,
    img:'/assets/img/boards/IMG-20230713-WA0060.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:9,
    img:'/assets/img/boards/project10.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:10,
    img:'/assets/img/boards/project11.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:11,
    img:'/assets/img/boards/project12.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:12,
    img:'/assets/img/boards/project13.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:13,
    img:'/assets/img/boards/project14.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:14,
    img:'/assets/img/boards/project15.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:15,
    img:'/assets/img/boards/project16.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:16,
    img:'/assets/img/boards/project17.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:17,
    img:'/assets/img/boards/project18.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:18,
    img:'/assets/img/boards/project19.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:19,
    img:'/assets/img/boards/project20.jpg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:20,
    img:'/assets/img/boards/project21.jpg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:21,
    img:'/assets/img/boards/project22.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:22,
    img:'/assets/img/boards/project23.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:23,
    img:'/assets/img/boards/project24.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:24,
    img:'/assets/img/boards/project25.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:25,
    img:'/assets/img/boards/project26.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:26,
    img:'/assets/img/boards/project27.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:27,
    img:'/assets/img/boards/project28.jpeg',
    tag:"Business",
    title:"Outdoor"
  },
  {
    id:28,
    img:'/assets/img/boards/project29.jpeg',
    tag:"Business",
    title:"Indoor"
  },
  {
    id:29,
    img:'/assets/img/boards/project30.jpeg',
    tag:"Business",
    title:"Indoor"
  },
];



const PortfolioItems = () => {

  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [isOpen, setIsOpen] = useState(false);
  // handleImagePopup
  const handleImagePopup = (i) => {
    console.log(i);
    setPhotoIndex(i);
    setIsOpen(true);
  };
  //  images
  const images = [...new Set(portfolio_data.map((item) => item.img))];

  return (
    <>
      <div className="project-page-list" style={{marginTop: '55px'}}>
        <div className="container">
          <div className="row">
            <div className="col">
              <p>Welcome to <b>Signage Tree!</b> We are your one-stop destination for all your printing and flex designing needs. With a wide array of services, we specialize in creating high-quality <b>Acp boards, Acp cladding,</b> and eye-catching <b>Acrylic 3D</b> letters that are sure to leave a lasting impression. Our expertise extends to crafting <b>Aluminium profile letters, Aluminium trim cap letters, Stainless steel letters, Titanium brass letters, and MS metal letters,</b> showcasing our commitment to precision and excellence. Additionally, we offer an extensive range of signage solutions, from Backlight glow sign boards to <b> Front-lit flex boards, Clippob signage to Blackout signage,</b> catering to all your indoor and outdoor advertising requirements. Whether it's <b>Fire Exit Led signage, Direction signage, Pylon signage, Pole signage, or Lollipop signage,</b>  we've got you covered. Our team of talented designers also specializes in Reception backdrops, bringing life to your brand space. Furthermore, our state-of-the-art printing services encompass <b>Flex printing, Vinyl printing, and Fabric printing,</b>  delivering vibrant and captivating results. Partner with us to elevate your brand's identity and make a lasting impact with our premium printing works and top-notch flex designs.</p>
            </div>
          </div>
          <div className="row pb-90" style={{marginTop: '55px'}}>
            {portfolio_data.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-12">
              <div className="pj-list-item mb-50">
                <div className="pj-list__img">
                <div className="fix">
                    <button
                      onClick={() => handleImagePopup(item.id)}
                      className="project-gallery-image"
                      
                    >
                    <img src={item.img} alt="" style={{width:"410px", height:"253px"}}/>

                    </button>
                  </div>
                </div>
                <span>
                  {/* <a href="#">{item.tag}</a> */}
                </span>
                <h4 className="pj-list__title">
                  {/* <Link to="/portfolio-details">{item.title}</Link> */}
                  {item.title}
                </h4>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
      {
        isOpen && (
          <ImagePopup
          images={images}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
        )
      }
    </>
  );
};

export default PortfolioItems;
