import React from "react";
import { Link } from "react-router-dom";

const AboutThree = () => {
  return (
    <>
      {/* <!-- tp-about-3 start  --> */}
      <div className="tp-ab-section pt-160">
        <div className="container">
          <div className="row align-items-center tp-flex-reverse">
            <div className="col-lg-6">
              <div className="tp-ab-info pr-40">
                <div className="tp-ab-subtitle mb-10">
                  <span>About us</span>
                </div>
                <h3 className="tp-ab-title mb-35">When it comes to signs, we are the solution</h3>
                <p>
                <b>At Signage Tree</b>, we are passionate about quality, striving for excellence, and prioritizing customer satisfaction. With a strong commitment to delivering exceptional products and services, we go above and beyond to meet and exceed our customers' expectations. Our dedication to excellence ensures that every signage solution we create is crafted with meticulous attention to detail, leaving a lasting impression on our valued clients.
                </p>
                <div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-ab-img text-md-end w-img">
                <img src="assets/img/about/Frame4.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- tp-about-3 end  --> */}
    </>
  );
};

export default AboutThree;
