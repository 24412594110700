import React from "react";

const CategoryThree = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area pt-140">
        <div className="container">
          <div className="tp-cta-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="tp-section-wrapper text-center">
                  <span className="tp-section-subtitle mb-25">
                    Get in touch with us
                  </span>
                  <h2 className="tp-section-title">
                  For any inquiries or collaboration opportunities, please don't hesitate to reach out to us. 
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default CategoryThree;
