import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        At Signage Tree, our mission is to be a leading provider of innovative and captivating signages and branding solutions that empower businesses to effectively communicate their message to the world. We strive to combine cutting-edge technology, artistic design, and exceptional craftsmanship to create visually striking and impactful signages that leave a lasting impression on our clients' target audience.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Our vision at Signage Tree is to be recognized as the most trusted and preferred signage partner in the industry, renowned for our relentless commitment to quality, creativity, and customer satisfaction. We envision expanding our footprint globally, catering to diverse businesses across various sectors, and setting new benchmarks in the signage and branding domain through continuous innovation and customer-centric approaches.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                       <b>Customer-Centricity:</b>  Our customers are at the heart of everything we do. We prioritize their needs and aspirations, ensuring that every signage and branding solution we deliver surpasses their expectations.
                      </p>

                      <p>
                       <b>Integrity & Reliability:</b> Honesty, transparency, and ethical practices are the cornerstones of our business. We take pride in being a dependable partner, delivering on promises, and upholding the highest standards of integrity in all our endeavours.
                      </p>
                       <p><b>Quality & Craftsmanship:</b> From concept to creation, we maintain an unwavering commitment to quality and precision. Our skilled artisans and state-of-the-art technology ensure that every signage we produce reflects our passion for perfection.</p>
                       <p><b>Innovation & Creativity:</b>
                        We embrace innovation and creativity as the driving force behind our success.
                       </p>
                       <p>As Signage Tree, we embark on a journey to revolutionize the signage industry, driven by our mission, guided by our vision, and anchored by our core values.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.png" alt="" />
                </div>
                {/* <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div> */}
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">20</h3>
                  <h4 className="box-subtitle">
                    Year of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
